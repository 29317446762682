import { useEffect, useRef, useState } from "react";

import { companyCopilotStore, MessageType } from "@/app/screens/modal/copilot";
import { completion } from "@/app/screens/modal/copilot/service/completion.tsx";
import { getTitle } from "@/app/screens/modal/copilot/service/title.tsx";

export function useTextAreaCopilot() {
  const streamingAnswer = "";
  const {
    addMessage,
    editMessage,
    state: { isLoading, companyId },
    currentConversation,
    editConversation,
    messages,
    setState,
  } = companyCopilotStore;
  const [input, setInput] = useState("");

  const inputRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  const handleSendMessage = async (text: string = "") => {
    if (!input.trim() && !text.trim()) return;

    const userMessage: MessageType = {
      id: `user-${Date.now()}`,
      role: "user",
      content: text.trim() || input.trim(),
      timestamp: new Date().toISOString(),
    };

    addMessage(userMessage);
    setInput("");
    setState({ isLoading: true });

    try {
      const responseId = `assistant-${Date.now()}`;
      const assistantMessage: MessageType = {
        id: responseId,
        role: "assistant",
        content: "",
        timestamp: new Date().toISOString(),
      };
      addMessage(assistantMessage);
      await completion({
        companyId,
        updateFn: (text) => {
          if (typeof text === "function" || !text?.text) return;
          editMessage(responseId, text.text);
        },
        messages: [...messages, userMessage].map((message) => ({ role: message.role, content: message.content })),
        systemMessage: currentConversation.systemPrompt,
        modelSettings: currentConversation.modelSettings,
      })
        .then(() => {
          if (messages.length <= 1) {
            getTitle(userMessage.content).then((title) => {
              editConversation({ title: `${currentConversation.companyMetadata.companyName} • ${title}` });
            });
          }
        })
        .catch(console.error);
      setState({ isLoading: false });
    } catch (error) {
      console.error("Error sending message:", error);
      setState({ isLoading: false });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage().catch();
    }
  };
  return { handleSendMessage, handleKeyDown, inputRef, input, setInput, addMessage, isLoading, streamingAnswer };
}
