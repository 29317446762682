import { observer } from "mobx-react";

import { Button, IconButtonV2 } from "@/app/components";
import { ChatLoading } from "@/app/screens/modal/copilot/components/chat-loading.tsx";
import { EmptyConversation } from "@/app/screens/modal/copilot/components/messages/empty-conversation.tsx";
import { useMessages } from "@/app/screens/modal/copilot/components/messages/use-messages.tsx";

export const ChatMessages = observer(function () {
  const { shownMessages, messagesEndRef, isLoading, copyToClipboard, addCommentLoading, handleSubmit } = useMessages();

  if (shownMessages.length === 0) {
    return <EmptyConversation />;
  }
  return (
    <div className={"my-4 flex h-full flex-1 flex-col overflow-y-auto p-2"}>
      {shownMessages.map((message) => (
        <div key={message.id} className={`flex ${message.role === "user" ? "justify-end" : "justify-start"}`}>
          <div
            className={`group relative max-w-[95%] rounded-sm px-4 py-2 ${
              message.role === "user"
                ? "rounded-br-none bg-light-green-100 text-gray-800"
                : "rounded-bl-none bg-white text-gray-800"
            }`}
          >
            <div className="whitespace-pre-wrap">{message.content}</div>
            <div className={`mt-1 text-xs ${message.role === "user" ? "text-gray-800" : "text-gray-500"}`}>
              {new Date(message.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
            </div>
            {message.role === "assistant" && (
              <div className="absolute -bottom-3 right-1 hidden rounded p-1 text-gray-500 hover:text-gray-700 group-hover:block">
                <div className={"flex items-center space-x-2"}>
                  <div>
                    <Button
                      variant={"outline"}
                      size={"sm"}
                      loading={addCommentLoading}
                      onClick={() => handleSubmit(message.content)}
                    >
                      Add as comment
                    </Button>
                  </div>
                  <IconButtonV2 icon="Copy" className={"size-2.5"} onClick={() => copyToClipboard(message.content)} />
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      {isLoading && <ChatLoading />}
      <div ref={messagesEndRef} />
    </div>
  );
});
