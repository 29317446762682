import { observer } from "mobx-react";

import { IconButtonV2 } from "@/app/components";
import { companyCopilotStore, type ConversationType } from "@/app/screens/modal/copilot";
import { Suggestions } from "@/app/screens/modal/copilot/components/suggestions.tsx";

export const ConversationsList = observer(() => {
  const { conversations, selectConversation, deleteConversation } = companyCopilotStore;
  return (
    <div className="flex flex-col space-y-2">
      {Object.entries(conversations)
        // .filter(([, { messages }]) => messages.length)
        .map(([id, { createdAt, title }]: [string, ConversationType]) => {
          return (
            <div
              key={id}
              onClick={() => {
                selectConversation(id);
              }}
              className="group flex cursor-pointer select-none items-center justify-between rounded-xs border bg-gray-100 p-2 transition-all hover:cursor-pointer hover:bg-white hover:shadow-xs active:scale-[.97]"
            >
              <div>
                <span className="text-sm font-semibold">{title}</span>
              </div>
              <div className={"flex items-center"}>
                <p className="text-xs text-gray-500">{new Date(createdAt).toLocaleString()}</p>
                <IconButtonV2
                  icon={"Trash"}
                  size={"xs"}
                  className={"hidden group-hover:flex"}
                  onClick={() => deleteConversation(id)}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
});
