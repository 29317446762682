import { useLayoutEffect, useRef } from "react";

import toast from "react-hot-toast";

import { companyCopilotStore } from "@/app/screens/modal/copilot";
import {
  AddCompanyCommentMutationVariables,
  GetCompanyCommentsDocument,
  useAddCompanyCommentMutation,
} from "@/app/service/company-comments.gql.ts";

export function useMessages() {
  const {
    messages,
    state: { isLoading, companyId },
  } = companyCopilotStore;
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { addComment, addCommentLoading } = useAddComment(companyId);

  const handleSubmit = (text: string) => {
    addComment({ text }).then(() => {
      toast.success("Comment added successfully.");
    });
  };

  useLayoutEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success("Copied to clipboard", { icon: "🗒️" }))
      .catch(() => toast.error("Failed to copy"));
  };
  const shownMessages = messages.filter(({ role }) => role !== "system");
  return { shownMessages, messagesEndRef, isLoading, copyToClipboard, addCommentLoading, handleSubmit };
}

const useAddComment = (companyId: string) => {
  const [submit, { loading }] = useAddCompanyCommentMutation({
    refetchQueries: [GetCompanyCommentsDocument],
  });

  const handleSubmit = (data: Omit<AddCompanyCommentMutationVariables, "companyId">) => {
    if (!companyId) {
      throw new Error("No company provided instance");
    }

    return submit({
      variables: {
        companyId,
        ...data,
      },
    });
  };
  return { addComment: handleSubmit, addCommentLoading: loading };
};
