import { CompanyMetadata, StateType, useCompanyCopilot } from "@/app/screens/modal/copilot";
import { Footer, Header, Body } from "@/app/screens/modal/copilot/components";

export const CompanyCopilot = ({
  copilotState,
  companyMetadata,
}: {
  copilotState: Partial<StateType>;
  companyMetadata: Partial<CompanyMetadata>;
}) => {
  useCompanyCopilot({ copilotState, companyMetadata });
  console.log("rerender");
  return (
    <div className="relative flex h-full flex-1 flex-col bg-white">
      <Header />
      <Body />
      <Footer />
    </div>
  );
};
