export function ChatLoading() {
  return (
    <div className="flex justify-start">
      <div className="rounded-lg rounded-bl-none bg-gray-100 px-4 py-2 text-gray-800">
        <div className="flex space-x-1">
          <div className="size-2 animate-bounce rounded-full bg-gray-500" />
          <div className="size-2 animate-bounce rounded-full bg-gray-500" style={{ animationDelay: "150ms" }} />
          <div className="size-2 animate-bounce rounded-full bg-gray-500" style={{ animationDelay: "300ms" }} />
        </div>
      </div>
    </div>
  );
}
